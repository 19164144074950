<script setup lang="ts">
useHead({
  bodyAttrs: {
    class: 'bg-green-500',
  },
});
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <div class="absolute h-[280px] w-full bg-green-300" />
    <div class="w-full">
      <TnHeader />
      <div class="min-h-[calc(100vh-var(--header-height))]">
        <div class="max-w-app relative mx-auto px-0 pb-6 pt-14 sm:px-6 lg:px-0">
          <slot />
        </div>
      </div>
    </div>

    <TnFooter class="mt-auto pb-[150px] sm:pb-6" />
  </div>
</template>
